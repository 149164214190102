export const PARAM_NAO_OBRIGA_ENDERECO = 108;
export const MOSTRAR_NOME_MODULO_OUVIDORIA = 116;
export const PERMITE_PROCESSO_DATA_RETROATIVA = 119;
export const TAMANHO_MAXIMO_ARQUIVOS_EXTERNO = 121;
export const TAMANHO_MAXIMO_ARQUIVOS_INTERNO = 130;
export const BLOQUEAR_EDICAO_TRAMITACAO_JA_EFETUADA = 135;
export const IMPRIME_PAPELETA_ARQUIVAMENTO = 136;
export const BLOQUEIO_VISUALIZACAO_PROCESSOS_ESPECIFICOS_USUARIO = 137;
export const SITUACAO_PADRAO_RECEBIMENTO = 138;
export const SITUACAO_PADRAO_ENCAMINHAMENTO = 139;
export const SITUACAO_PADRAO_ARQUIVAMENTO = 140;
export const SITUACAO_PADRAO_REABERTURA = 141;
export const GERA_PAPELETA_AUTOMATICO = 144;
export const NAO_DEMONSTRA_AVISO_PROCESSO_MESMO_ASSUNTO_REQUERENTE = 145;
export const PERMITE_REORDENAR_ANEXOS_DO_PROCESSO = 152;
export const BLOQUEIA_ALTERACAO_PARECER = 206;
export const PERMITE_EXCLUSAO_SOLICITACOES_ASSINATURA_SOLICITANTE = 154;
export const REALIZA_FECHAMENTO_GERAL_ARQUIVAMENTO = 156;
export const RECEBE_AUTOMATICAMENTE_PROCESSO_NAO_FISICO_ENCAMINHAMENTO = 157;
export const ATRIBUI_PERMISSAO_LOCAIS_FILHOS = 158;
export const PARAM_PROCESSO_MP = 177;
export const NAO_EXIBE_UPLOAD_TRAMITACAO = 181;
export const NAO_EXIBE_PROC_FIS_TRAMITACAO = 182;
export const PARAM_QTD_DIAS_ANEXO_EXTERNO = 184;
export const NAO_CARREGA_REJEITADOS_AGRUPAMENTO_ARQUIVO = 185;
export const BLOQUEIA_ALTERACAO_ANDAMENTO_USUARIO_LOGADO_DIFERENTE = 186;
export const BLOQUEIA_ALTERACAO_ANDAMENTO_COM_ANDAMENTO_POSTERIOR = 187;
export const PERMITE_RENOMEAR_ANEXOS = 188;
export const IMPRIME_PAPELETA_ENCAMINHAMENTO = 191;
export const POSSIBILITAR_SELECAO_ENTIDADE_AMBIENTE_EXTERNO = 196;
export const EXIBIR_ORDEM_CRESCENTE_ANEXO = 197;
export const EXIBIR_ORDEM_GERAL_ANEXO = 198;
export const BLOQUEIA_REORDENACAO_ANEXO_TRAMITE_DIFERENTE_DO_ATUAL = 201;
export const DEMONSTRA_PARAMETRO_EXPORTACAO_MODELO_DADOS = 204;
export const PERMITE_SOLICITACAO_ASSINATURA_USUARIO_DUPLICADA_ARQUIVO = 153;
export const BLOQUEAR_ABERTURA_ANONIMA_ABERTURA_EXTERNA = 210;
export const OBRIGA_PREENCHIMENTO_REQUERIMENTO_ABERTURA_PROCESSO = 211;
export const EXIBE_COMPLEMENTO_ASSUNTO_LISTAGEM_PROCESSO = 212;
